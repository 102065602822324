<template>
  <div class="orderconfirm-wrap">
    <div class="bread-wrap">
      <span @click="navigateback" class="backbtn"><i class="el-icon-arrow-left"></i></span>
      <span class="bread-title">订单确认</span>
    </div>
    <div class="order-wrap">
      <div class="order-left">
        <p class="title-wrap">
          <i class="iconfont icon-jiaofeizhangdan titleicon"></i><span class="title">订单信息</span>
        </p>
        <div class="ff-detail">
          <span class="dtcontent">线路ID</span><span class="dttext">{{ resourceId }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">线路原规格</span><span class="dttext">{{ productTypeMap[productBefore] }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">线路升级规格</span><span class="dttext">{{ productTypeMap[productAfter] }}</span>
        </div>

        <div class="ff-detail">
          <span class="dtcontent">支付方式</span><span class="dttext">{{ payText }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtprice">合计费用</span><span class="dtprice">{{ totalAmount/100 }} 元</span>
        </div>
        <el-button v-if="amountPay" @click="payNow" class="paybtn" size="small" type="primary">立即支付</el-button>
      </div>
      <div class="order-right" v-if="!amountPay">
        <div class="qrcode-wrap">
          <div class="qrcode" id="qrcodeImg"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AutoRenewMap } from "@/utils/constants";
import formatdate from "@/utils/format";
import navigation from "@/utils/navigation";
import { ProductTypeMap } from "@/utils/constants";
import QRCode from "qrcodejs2";
export default {
  name: "lineorderconfirm",
  data () {
    checkTimer: null;
    const date = new Date();
    return {
      tradeNo: "",
      totalAmount: 0,
      nowdate: formatdate(parseInt(date.getTime() / 1000), "YYYY-MM-DD"),
      amountPay: false,
      payText: "",
      payType: "",
      productBefore: "",
      productAfter: "",
      resourceId: "",
      productTypeMap: ProductTypeMap,
    };
  },
  mounted () {
    this.tradeNo = this.$route.params.tradeNo;
    this.totalAmount = this.$route.params.totalAmount;
    this.payType = this.$route.params.payType;
    this.productBefore = this.$route.params.productBefore;
    this.productAfter = this.$route.params.productAfter;
    this.resourceId = this.$route.params.resourceId;

    if (this.payType == "amount") {
      this.amountPay = true;
      this.payText = "余额支付";
    } else if (this.payType == "alipay_page") {
      this.amountPay = false;
      this.payText = "支付宝扫码支付";
      this.getqrcode();
    } else if (this.payType == "wechatpay_native") {
      this.amountPay = false;
      this.payText = "微信扫码支付";
      this.getqrcode();
    } else {
      this.$message.error('参数错误');
      this.navigation("linelist");
      return
    }
  },
  beforeDestroy () {
    console.log("beforeDestroy called.");
    if (this.checkTimer != null) {
      clearInterval(this.checkTimer);
      this.checkTimer = null;
    }
  },
  methods: {
    qrcode (url) {
      return new QRCode("qrcodeImg", {
        width: 230,
        height: 230,
        text: url,
        colorDark: "#000",
        colorLight: "#fff",
      });
    },
    navigateback () {
      if (this.checkTimer != null) {
        console.log("navigateback called.");
        clearInterval(this.checkTimer);
        this.checkTimer = null;
      }
      navigation.back();
    },
    navigation (url) {
      navigation.navigateTo({
        url,
      });
    },
    getpayconfirm () {
      this.checkTimer = setInterval(() => {
        this.$store
          .dispatch("pay/getpayconfirm", {
            TradeNo: this.tradeNo,
            PayType: this.payType,
            OrderType: "Upgrade"
          })
          .then((res) => {
            if (res.RetCode == 0 && res.Status == "Paid") {
              clearInterval(this.checkTimer);
              this.$message.success("支付成功！");
              this.navigation("linelist");
            } else if (res.RetCode !== 0) {
              clearInterval(this.checkTimer);
            }
          });
      }, 1000);
    },
    getqrcode () {
      const payload = {
        TradeNo: this.tradeNo,
        PayType: this.payType,
        OrderType: "Upgrade"
      };
      this.$store.dispatch("pay/getpaymentparams", { ...payload }).then((res) => {
        if (res.RetCode == 0) {
          this.qrcode(res.PayReq);
          this.getpayconfirm();
        }
      });
    },
    payNow () {
      const payload = {
        TradeNo: this.tradeNo,
        PayType: "amount",
        OrderType: "Upgrade",
      };
      this.$store.dispatch("pay/getpaymentparams", { ...payload }).then((res) => {
        console.log("amount pay response: " + JSON.stringify(res));
        if (res.RetCode == 0) {
          this.$message.success("支付成功！");
          this.navigation("linelist");
        } else {
          this.$message.error("支付失败！");
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>